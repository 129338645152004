import React from 'react';
import logo from './NeuraveLogo.png';
import './App.css';

function App() {
  return (
    <div className="LandingPage">
      <img src={logo} className="Logo"></img>
      <ul className="Description">
        <li className="DescItem">We use <strong>deep learning</strong> to optimize how surgeons <strong>monitor patients</strong> during invasive procedures.</li>
        <li className="DescItem">Our current focus is improving <strong> intraoperative neuromonitoring (IOM) </strong>during spinal and cranial surgeries.</li>
        <li className="DescItem">Neurave offers a desktop application that interfaces with <strong>any surgical software</strong> and <strong>identifies instabilities</strong> with over 93% accuracy. Become a beta user <a href="mailto:john@neurave.net,alex@neurave.net?subject=Beta%20User%20Sign-Up&body=I%20want%20to%20be%20added%20to%20the%20Beta%20User%20list!">here</a>.</li> 
      </ul>
      <div className="Footer">
        Interested in what we're doing? <a href="mailto:john@neurave.net,alex@neurave.net">Contact us</a>.
      </div>
    </div>
  );
}

export default App;
